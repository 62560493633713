// 이메일 검사
function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export { validateEmail };

// 아이디 검사
function validateId(id) {
	const re = /^[A-Za-z0-9]{6,12}$/;
	return re.test(String(id.toLowerCase()));
}

export { validateId };

// 비밀번호 검사
function validatePw(pw) {
	const re =
		/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&^])[A-Za-z\d$@$!%*#?&^]{10,20}$/;
	return re.test(String(pw.toLowerCase()));
}

export { validatePw };
