<template>
	<!--문의하기 페이지: 비회원-->
	<div class="container--center">
		<!--문의하기 폼-->
		<div class="form--large">
			<!--이름-->
			<div class="form__group">
				<label for="input-1">* 이름</label>
				<input
					id="input-1"
					v-model="inquiry.userName"
					placeholder="이름(필수)"
					type="text"
					maxlength="20"
				/>
			</div>
			<!--회사 이름-->
			<div class="form__group">
				<label for="input-2">* 회사 이름</label>
				<input
					id="input-2"
					v-model="inquiry.userCompany"
					placeholder="회사 이름(필수)"
					type="text"
					maxlength="100"
				/>
			</div>
			<!--이메일-->
			<div class="form__group">
				<label for="input-3">* 이메일</label>
				<input
					id="input-3"
					v-model="inquiry.userEmail"
					placeholder="이메일(필수)"
					type="text"
					maxlength="60"
				/>
			</div>
			<!--핸드폰 번호-->
			<div class="form__group">
				<label for="input-4">* 핸드폰 번호</label>
				<input
					id="input-4"
					v-model="inquiry.userPhone"
					placeholder="핸드폰 번호(필수)"
					type="text"
					maxlength="11"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
				/>
			</div>
			<!--문의 제목-->
			<div class="form__group">
				<label for="input-5">* 문의 제목</label>
				<input
					id="input-5"
					v-model="inquiry.title"
					placeholder="문의 제목(필수)"
					type="text"
					maxlength="200"
				/>
			</div>
			<!--문의 구분-->
			<div class="form__group">
				<label for="input-6">* 문의 구분</label>
				<select v-model="inquiry.selected" name="inquiry" id="input-6">
					<option value="">문의 구분(필수)</option>
					<option value="productName">제품 서비스</option>
					<option value="svUse">이용 서비스</option>
					<option value="repair">관리 / 유지보수</option>
					<option value="buyIt">구매 / 비용</option>
					<option value="cloud">클라우드</option>
					<option value="server">서버</option>
					<option value="connect">연동</option>
					<option value="error">오류 / 신고</option>
					<option value="member">회원 정보</option>
					<option value="etc">기타</option>
				</select>
			</div>
			<!--문의 내용-->
			<div class="form__group">
				<label for="input-7">* 문의 내용</label>
				<textarea
					v-model="inquiry.contents"
					id="input-7"
					placeholder="문의 내용(필수)"
					type="text"
					maxlength="500"
				/>
			</div>
			<!--동의하기-->
			<div class="agree__group">
				<input type="checkbox" id="checkbox-1" v-model="inquiry.agree" />
				<router-link to="/terms" target="_blank">이용약관</router-link>
				<span> &nbsp;및&nbsp;</span>
				<router-link to="/privacy" target="_blank">
					개인정보 처리방침
				</router-link>
				<span>&nbsp;에 동의합니다.</span>
			</div>
			<!--문의하기 제출 버튼-->
			<button
				type="submit"
				class="btn__form"
				:class="{ btnDisabled: !allCheck }"
				:disabled="!allCheck"
				@click="submitForm()"
			>
				문의하기
			</button>
		</div>
	</div>
</template>

<script>
import { validateEmail } from '@/utils/validation';
import InquiryService from '../../services/inquiryService';

export default {
	data() {
		return {
			inquiry: {
				userName: '',
				userCompany: '',
				userEmail: '',
				userPhone: '',
				title: '',
				contents: '',
				// 문의 구분 선택된 항목
				selected: '',
				// 동의 여부
				agree: false,
			},
		};
	},
	computed: {
		/* 문의하기 폼 유효성 검사 */
		// 이메일 검사
		emailState() {
			if (this.inquiry.userEmail != '') {
				return validateEmail(this.inquiry.userEmail);
			} else {
				return null;
			}
		},
		// 문의하기 버튼 활성화 검사(문의하기 필수항목 입력 완료 시 버튼색 변경 및 활성화)
		allCheck() {
			if (
				this.inquiry.userName &&
				this.inquiry.userCompany &&
				this.emailState == true &&
				this.inquiry.userPhone &&
				this.inquiry.title &&
				this.inquiry.contents &&
				this.inquiry.selected &&
				this.inquiry.agree == true
			) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		submitForm() {
			this.$router.push('./inquiry/complete');
			const formData = {
				userName: this.inquiry.userName,
				userCompany: this.inquiry.userCompany,
				userEmail: this.inquiry.userEmail,
				userPhone: this.inquiry.userPhone,
				title: this.inquiry.title,
				contents: this.inquiry.contents,
				selected: this.inquiry.selected,
			};

			InquiryService.inquirySend(formData)
				.then(res => {
					if (res.data.message == 'SUCCESS') {
						alert('문의가 완료되었습니다.');
						console.log('문의하기 폼 전송');
					}
				})
				.catch(err => {
					alert(err);
				});
		},
	},
};
</script>

<style></style>
